import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRateMutationVariables = Types.Exact<{
  input: Types.UpdateRateDto;
}>;

export type UpdateRateMutationResponse = { __typename?: 'Mutation' } & {
  updateRate: { __typename?: 'Rate' } & Pick<
    Types.Rate,
    '_id' | 'createdAt' | 'createdBy' | 'isDeleted' | 'ratio' | 'type' | 'updatedAt' | 'updatedBy'
  >;
};

export const UpdateRateDocument = gql`
  mutation updateRate($input: UpdateRateDto!) {
    updateRate(input: $input) {
      _id
      createdAt
      createdBy
      isDeleted
      ratio
      type
      updatedAt
      updatedBy
    }
  }
`;
export function useUpdateRateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRateMutationResponse, UpdateRateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRateMutationResponse, UpdateRateMutationVariables>(UpdateRateDocument, options);
}
export type UpdateRateMutationHookResult = ReturnType<typeof useUpdateRateMutation>;
export type UpdateRateMutationResult = Apollo.MutationResult<UpdateRateMutationResponse>;
export type UpdateRateMutationOptions = Apollo.BaseMutationOptions<
  UpdateRateMutationResponse,
  UpdateRateMutationVariables
>;
