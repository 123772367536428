import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GiveEnergyRateQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GiveEnergyRateQueryResponse = { __typename?: 'Query' } & {
  giveEnergyRate?: Types.Maybe<
    { __typename?: 'ConversionRateInterface' } & Pick<
      Types.ConversionRateInterface,
      '_id' | 'createdAt' | 'createdBy' | 'isDeleted' | 'ratio'
    >
  >;
};

export const GiveEnergyRateDocument = gql`
  query giveEnergyRate {
    giveEnergyRate {
      _id
      createdAt
      createdBy
      isDeleted
      ratio
    }
  }
`;
export function useGiveEnergyRateQuery(
  baseOptions?: Apollo.QueryHookOptions<GiveEnergyRateQueryResponse, GiveEnergyRateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GiveEnergyRateQueryResponse, GiveEnergyRateQueryVariables>(GiveEnergyRateDocument, options);
}
export function useGiveEnergyRateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GiveEnergyRateQueryResponse, GiveEnergyRateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GiveEnergyRateQueryResponse, GiveEnergyRateQueryVariables>(
    GiveEnergyRateDocument,
    options,
  );
}
export function useGiveEnergyRateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GiveEnergyRateQueryResponse, GiveEnergyRateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GiveEnergyRateQueryResponse, GiveEnergyRateQueryVariables>(
    GiveEnergyRateDocument,
    options,
  );
}
export type GiveEnergyRateQueryHookResult = ReturnType<typeof useGiveEnergyRateQuery>;
export type GiveEnergyRateLazyQueryHookResult = ReturnType<typeof useGiveEnergyRateLazyQuery>;
export type GiveEnergyRateSuspenseQueryHookResult = ReturnType<typeof useGiveEnergyRateSuspenseQuery>;
export type GiveEnergyRateQueryResult = Apollo.QueryResult<GiveEnergyRateQueryResponse, GiveEnergyRateQueryVariables>;
