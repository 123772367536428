import { Breadcrumb, Button, Col, Form, Input, Row } from 'antd';
import { ConversionRateInterface, RateTypeEnum } from 'src/graphql/type.interface';
import { GET_LIST_ERROR_MESSAGE, UPDATE_ERROR_MESSAGE, UPDATE_SUCCESS_MESSAGE } from 'src/constants/message';
import { number, object } from 'yup';
import { useEffect, useState } from 'react';

import Container from 'src/components/Container';
import { FIELD_REQUIRED } from 'src/constants/message';
import { enqueueSnackbar } from 'notistack';
import { useCreateRateMutation } from 'src/graphql/mutations/createRate.graphql';
import { useGiveEnergyRateQuery } from 'src/graphql/queries/giveEnergyRate.graphql';
import { useUpdateRateMutation } from 'src/graphql/mutations/updateRate.graphql';

type TypeForm = {
  ratio: number;
};
export default function ConfigGiftEnergy() {
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(true);
  const [data, setData] = useState<ConversionRateInterface>();
  const yupSync = {
    async validator({ field }: any, value: any) {
      await object({
        ratio: number()
          .positive('Tỷ lệ phải là một số nguyên dương lớn hơn 0')
          .integer('Tỷ lệ phải là một số nguyên dương')
          .typeError('Tỷ lệ phải là một số nguyên dương lớn hơn 0')
          .required(FIELD_REQUIRED),
      }).validateAt(field, { [field]: value });
    },
  };

  const { refetch } = useGiveEnergyRateQuery({
    onCompleted: ({ giveEnergyRate }) => {
      setData(giveEnergyRate as ConversionRateInterface);
    },
    onError() {
      enqueueSnackbar(GET_LIST_ERROR_MESSAGE, { variant: 'error' });
    },
  });

  const [updateTokenRateMutation] = useUpdateRateMutation({
    onCompleted: () => {
      enqueueSnackbar(UPDATE_SUCCESS_MESSAGE, { variant: 'success' });
      refetch();
    },
    onError() {
      enqueueSnackbar(UPDATE_ERROR_MESSAGE, { variant: 'error' });
    },
  });

  const [createRateMutation] = useCreateRateMutation({
    onCompleted: () => {
      enqueueSnackbar(UPDATE_SUCCESS_MESSAGE, { variant: 'success' });
      refetch();
    },
    onError() {
      enqueueSnackbar(UPDATE_ERROR_MESSAGE, { variant: 'error' });
    },
  });

  useEffect(() => {
    !data?._id ? setIsEdit(false) : setIsEdit(true);
    const { ratio } = data ?? { ratio: 0 };
    form.setFieldsValue({
      ratio: ratio,
    });
  }, [data]);

  const hasErrors = () => {
    return form.getFieldsError().some(({ errors }: any) => errors.length);
  };

  const onFinish = (form: TypeForm) => {
    if (data?._id) {
      updateTokenRateMutation({
        variables: {
          input: {
            _id: data?._id ?? '',
            ratio: Number(form.ratio),
          },
        },
      });
      setIsEdit(true);
    } else {
      createRateMutation({
        variables: {
          input: {
            ratio: Number(form.ratio),
            type: RateTypeEnum.GIVE_ENERGY,
          },
        },
      });
      setIsEdit(true);
    }
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: 'Số lượng energy tặng bạn bè',
          },
        ]}
      />
      <Container title="Chỉnh sửa cấu hình số lượng energy tặng bạn bè">
        <Row>
          <Col offset={3} span={18}>
            <Form form={form} onFinish={onFinish}>
              <Form.Item
                label={
                  <span>
                    Số Energy<span style={{ color: 'red' }}>*</span>
                  </span>
                }
                required={false}
                name="ratio"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[yupSync]}
              >
                <Input disabled={isEdit} />
              </Form.Item>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  type="primary"
                  htmlType={isEdit ? 'submit' : 'button'}
                  onClick={() => (hasErrors() ? setIsEdit(isEdit) : setIsEdit(!isEdit))}
                  disabled={hasErrors()}
                >
                  {isEdit ? 'Sửa' : 'Lưu'}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
