import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Breadcrumb, Button, Card, Dropdown, Space, Table } from 'antd';
import { PATH } from 'src/configs/path';
import { Register } from 'src/types/register';
import Container from 'src/components/Container';
import servicePackage, { PackageQuery } from 'src/apis/service/package';
import { DELETE_ERROR_MESSAGE, DELETE_SUCCESS_MESSAGE, GET_LIST_ERROR_MESSAGE } from 'src/constants/message';
import { Icon } from '@iconify/react';
import BtnPermission from 'src/components/BtnPermission';
import { PermissionType } from 'src/enums/permissionEnum';

export default () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Register[]>([]);
  const [filter, setFilter] = useState<PackageQuery>({ limit: 10, page: 1 });
  const [totalItems, setTotalItems] = useState<number>(0);

  const { refetch } = useQuery(['getSpecialCodeCP'], () => servicePackage.getSpecialCodeCP(filter), {
    retry: 0,
    refetchOnWindowFocus: false,
    onError() {
      enqueueSnackbar(GET_LIST_ERROR_MESSAGE, { variant: 'error' });
    },
    onSuccess: (data) => {
      setTableData(data?.data?.items);
      setTotalItems(data.data.paging.totalItems);
    },
  });

  const { mutateAsync: mutateDelete } = useMutation((id: number) => servicePackage.deleteCommandCode(id), {
    onError() {
      enqueueSnackbar(DELETE_ERROR_MESSAGE, { variant: 'error' });
    },
    onSuccess(data) {
      if (data.code === 200) {
        enqueueSnackbar(DELETE_SUCCESS_MESSAGE, { variant: 'success' });
        refetch();
      } else {
        enqueueSnackbar(data.message, { variant: 'error' });
      }
    },
  });

  const pagination: TablePaginationConfig = {
    pageSize: filter.limit as number,
    current: filter.page as number,
    total: totalItems,
    onChange: (page: number, pageSize: number) => {
      setFilter({ ...filter, limit: pageSize, page });
    },
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  const handleItem = (record: any) => ({
    items: [
      {
        key: '1',
        label: (
          <BtnPermission
            action={PermissionType.DELETE}
            moduleName={'package'}
            subModuleName={'command-special'}
            onDeleteClick={() => mutateDelete(record.id)}
          />
        ),
      },
      {
        key: '2',
        label: (
          <BtnPermission
            action={PermissionType.UPDATE}
            moduleName={'package'}
            subModuleName={'command-special'}
            recordId={record.id.toString()}
          />
        ),
      },
    ],
  });

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'contentProvider',
      key: 'contentProvider',
      render: (contentProvider) => <>{contentProvider?.name}</>,
    },
    {
      title: 'Cú pháp',
      dataIndex: 'command',
      key: 'command',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Chức năng',
      key: 'function',
      align: 'center',
      width: 120,
      render: (_, record) => (
        <Space size="middle">
          <Dropdown menu={handleItem(record)} trigger={['click']}>
            <Button type="primary" icon={<Icon icon="tabler:dots-vertical" />} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.package.package.list)}>
                Gói cước
              </p>
            ),
          },
          {
            title: <p style={{ cursor: 'default' }}>Cú pháp đặc biệt</p>,
          },
          { title: 'Danh sách' },
        ]}
      />
      <Card size="small">
        <BtnPermission action={PermissionType.CREATE} moduleName="package" subModuleName="command-special" />
      </Card>
      <div>
        <Container title="Cú pháp đặc biệt">
          <Table
            columns={columns}
            dataSource={tableData}
            rowKey="id"
            pagination={tableData.length > 0 ? pagination : false}
          />
        </Container>
      </div>
    </Space>
  );
};
