import { EventTouramentStatusEnum, EventTouramentTypeEnum, EventTouramentRewardEnum } from 'src/graphql/type.interface';
import { CheckoutTypeEnum } from './packageDisplay';

export enum MatchStatusEnum {
  WIN = 'WIN',
  LOSE = 'LOSE',
  DRAW = 'DRAW',
}

export const statusOptions = [
  { value: EventTouramentStatusEnum.UPCOMING, label: 'Sắp diễn ra', color: 'warning' },
  { value: EventTouramentStatusEnum.HAPPENING, label: 'Đang diễn ra', color: 'geekblue' },
  { value: EventTouramentStatusEnum.FINISHED, label: 'Đã kết thúc', color: 'green' },
  { value: 'ALL', label: 'Tất cả', color: 'green' },
];

export const typeOptions = [
  { value: EventTouramentTypeEnum.FREE, label: 'Miễn phí' },
  { value: EventTouramentTypeEnum.PREMIUM, label: 'Cao cấp' },
  { value: 'ALL', label: 'Tất cả' },
];

export const checkoutType = [
  { value: CheckoutTypeEnum.WALLET, label: 'Ví điện tử' },
  { value: CheckoutTypeEnum.BANK, label: 'Ngân hàng nội địa' },
  { value: CheckoutTypeEnum.INTERNATIONAL_CARD, label: 'Thẻ quốc tế' },
];

export const Display_Match_Status = [
  { value: MatchStatusEnum.WIN, label: 'Thắng' },
  { value: MatchStatusEnum.DRAW, label: 'Hòa' },
  { value: MatchStatusEnum.LOSE, label: 'Thua' },
];

export const rewardOptions = [
  {
    checked: false,
    type: EventTouramentRewardEnum.ENERGY,
    name: 'Energy',
    ranking: [
      {
        type: 'Rone',
        value: 0,
        name: 'Hạng 1',
      },
      {
        type: 'Rtwo',
        value: 0,
        name: 'Hạng 2',
      },
      {
        type: 'Rthree',
        value: 0,
        name: 'Hạng 3',
      },
      {
        type: 'Rfour',
        value: null,
        name: 'Hạng 4 - 100',
      },
    ],
  },
  {
    checked: false,
    type: EventTouramentRewardEnum.POINT,
    name: 'GPoint',
    ranking: [
      {
        type: 'Gone',
        value: 0,
        name: 'Hạng 1',
      },
      {
        type: 'Gtwo',
        value: 0,
        name: 'Hạng 2',
      },
      {
        type: 'Gthree',
        value: 0,
        name: 'Hạng 3',
      },
      {
        type: 'Gfour',
        value: null,
        name: 'Hạng 4 - 100',
      },
    ],
  },
  {
    checked: false,
    type: EventTouramentRewardEnum.CASH,
    name: 'Tiền mặt',
    ranking: [
      {
        type: 'Mone',
        value: 0,
        name: 'Hạng 1',
      },
      {
        type: 'Mtwo',
        value: 0,
        name: 'Hạng 2',
      },
      {
        type: 'Mthree',
        value: 0,
        name: 'Hạng 3',
      },
      {
        type: 'Mfour',
        value: null,
        name: 'Hạng 4 - 100',
      },
    ],
  },
];
