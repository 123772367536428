import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Breadcrumb, Card, Col, DatePicker, Row, Space, Table, Tag } from 'antd';
import { PATH } from 'src/configs/path';
import Container from 'src/components/Container';
import { ReportPaymentQuery } from 'src/apis/service/payment';
import { GET_LIST_ERROR_MESSAGE } from 'src/constants/message';
import { Payment } from 'src/types/payment';
import { PaymentTransactionStatusEnum } from 'src/enums/payment.enum';
import { getBillingChannelName, paymentTransactionStatus } from 'src/constants/payment';
import dayjs from 'dayjs';
import { FORMAT_TIME } from 'src/constants/common';
import { RangeValue } from 'rc-picker/lib/interface';
import { Icon } from '@iconify/react';
import HistoryCDRMMRespon from 'src/apis/service/cdrMM';

export default () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Payment[]>([]);
  const [filter, setFilter] = useState<ReportPaymentQuery>({ limit: 10, page: 1 });
  const [totalItems, setTotalItems] = useState<number>(0);

  const { refetch } = useQuery(['getHistoryCDRMM', filter], () => HistoryCDRMMRespon.getHistoryCDRMM(filter), {
    retry: 0,
    refetchOnWindowFocus: false,
    onError() {
      enqueueSnackbar(GET_LIST_ERROR_MESSAGE, { variant: 'error' });
    },
    onSuccess: (data) => {
      setTableData(data?.data?.items);
      setTotalItems(data.data?.meta?.totalItems);
    },
  });

  const pagination: TablePaginationConfig = {
    pageSize: filter.limit as number,
    current: filter.page as number,
    total: totalItems,
    onChange: (page: number, pageSize: number) => {
      setFilter({ ...filter, limit: pageSize, page });
    },
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  const columns: ColumnsType<any> = [
    {
      title: 'File đối soát',
      dataIndex: 'fileType',
      key: 'fileType',
      render: (value) => (value === 'SUCCESS' ? <Tag color="success">{value}</Tag> : <Tag color="error">{value}</Tag>),
    },
    {
      title: 'Mã giao dịch',
      dataIndex: 'transactionId',
      key: 'transactionId',
    },

    {
      title: 'Giá',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => (
        <>
          <span>{`${dayjs(value).format(FORMAT_TIME)}`}</span>
        </>
      ),
    },
    {
      title: 'Xác thực',
      dataIndex: 'isVerification',
      key: 'isVerification',
      render: (value) => (
        <>
          {value !== null ? (
            value ? (
              <div style={{ color: '#22ec3e' }}>OK</div>
            ) : (
              <div style={{ color: '#ec2222' }}>NOT OK</div>
            )
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'Lý do',
      dataIndex: 'reason',
      key: 'reason',
    },
  ];

  const handleSearchWithCreateTime = (date: RangeValue<dayjs.Dayjs>) => {
    setFilter({
      ...filter,
      page: 1,
      startTime: date ? dayjs(date[0]).startOf('day').toISOString() : undefined,
      endTime: date ? dayjs(date[1]).endOf('day').toISOString() : undefined,
    });
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: <p style={{ cursor: 'default' }}>Gói cước</p>,
          },
          {
            title: <p style={{ cursor: 'default' }}>Lịch sử file CDR Mobifone Money</p>,
          },
          { title: 'Danh sách' },
        ]}
      />
      <Card size="small">
        <Row gutter={24} justify={'end'}>
          <Col span={5}>
            <span>Khoảng thời gian tìm kiếm</span>
            <DatePicker.RangePicker
              format={'DD/MM/YYYY HH:mm'}
              style={{ width: '100%' }}
              showTime
              onChange={(date) => handleSearchWithCreateTime(date)}
              inputReadOnly
            />
          </Col>
        </Row>
      </Card>
      <div>
        <Container title="Lịch sử file CDR Mobifone Money">
          <Table columns={columns} dataSource={tableData} rowKey="id" pagination={pagination} />
        </Container>
      </div>
    </Space>
  );
};
