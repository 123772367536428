import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRateMutationVariables = Types.Exact<{
  input: Types.RateDto;
}>;

export type CreateRateMutationResponse = { __typename?: 'Mutation' } & {
  createRate: { __typename?: 'Rate' } & Pick<
    Types.Rate,
    '_id' | 'createdAt' | 'createdBy' | 'isDeleted' | 'ratio' | 'type' | 'updatedAt' | 'updatedBy'
  >;
};

export const CreateRateDocument = gql`
  mutation createRate($input: RateDto!) {
    createRate(input: $input) {
      _id
      createdAt
      createdBy
      isDeleted
      ratio
      type
      updatedAt
      updatedBy
    }
  }
`;
export function useCreateRateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRateMutationResponse, CreateRateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRateMutationResponse, CreateRateMutationVariables>(CreateRateDocument, options);
}
export type CreateRateMutationHookResult = ReturnType<typeof useCreateRateMutation>;
export type CreateRateMutationResult = Apollo.MutationResult<CreateRateMutationResponse>;
export type CreateRateMutationOptions = Apollo.BaseMutationOptions<
  CreateRateMutationResponse,
  CreateRateMutationVariables
>;
