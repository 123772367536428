import { callApi } from 'src/apis/connection/callApi';

export type RankEventTournamentType = {
  eventRefId: string;
  page: number;
  limit: number;
};

export type RankEventExcelType = {
  eventRefId: string;
};

export type AddEnergyAndGPoint = {
  gPoint: number;
  energy: number;
  userRefId: number;
  eventRefId: string;
};

export default {
  updateIgnoreTimeTournament(body: any, queryParams: any): Promise<any> {
    return callApi({ method: 'put', uri: `/mobile/touraments/event/${queryParams}`, body });
  },

  getEventTourByRefId(id: string): Promise<any> {
    return callApi({ method: 'get', uri: `/mobile/touraments/event-by-ref-id/${id}` });
  },
  getRankEventTournament(query: RankEventTournamentType): Promise<any> {
    return callApi({
      method: 'get',
      uri: `/mobile/ranks/events/cms`,
      query,
    });
  },
  exportRankEventExcel(query: RankEventExcelType): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/mobile/ranks/events/cms/excel',
      responseType: 'blob',
      query,
    });
  },
  addEnergyAndGpoint(body: AddEnergyAndGPoint): Promise<any> {
    return callApi({
      method: 'post',
      uri: '/mobile/player-resources/cms/energy-gpoint',
      body,
    });
  },
  getPlayerByUserRefId(userRefId: string) {
    return callApi({
      method: 'get',
      uri: `/mobile/player-resources/cms/${userRefId}`,
    });
  },
  getHistoryPvP(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/cms/pvp-history',
      query,
    });
  },
};
