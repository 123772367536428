import { TypeActiveCommentStatus } from './commentStatus';

export enum CheckoutTypeEnum {
  WALLET = 'WALLET',
  BANK = 'BANK',
  INTERNATIONAL_CARD = 'INTERNATIONAL_CARD',
}

export const packagePaymentMethod: TypeActiveCommentStatus = {
  SMS: {
    text: 'SMS',
    color: 'processing',
  },
  IAP: {
    text: 'IAP',
    color: 'success',
  },
  MOBIFONE_MONEY: {
    text: 'MobiFone Money',
    color: 'success',
  },
};

export const transactionHistory = [
  {
    text: 'Thành công',
    color: 'success',
  },
  {
    text: 'Thất bại',
    color: 'error',
  },
];
