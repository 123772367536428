import { Breadcrumb, Card, Space, Form, Input, DatePicker, Select } from 'antd';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useState } from 'react';
import Container from 'src/components/Container';
import { GET_LIST_ERROR_MESSAGE } from 'src/constants/message';
import type { TableRanking } from 'src/graphql/type.interface';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import ServiceEventTournamentRepository from 'src/apis/service/eventTournament';
import { Display_Match_Status } from 'src/constants/event-tournament';

export default () => {
  const [dataTable, setDataTable] = useState<TableRanking[]>([]);
  const [filter, setFilter] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [item, setItem] = useState<number>(0);

  const { refetch } = useQuery(['getHistoryPvP'], () => ServiceEventTournamentRepository.getHistoryPvP(filter), {
    retry: 0,
    refetchOnWindowFocus: false,
    onError() {
      enqueueSnackbar(GET_LIST_ERROR_MESSAGE, { variant: 'error' });
    },
    onSuccess: (data) => {
      setItem(data.data.paging?.totalItems);

      setDataTable(data?.data.items);
    },
  });

  const pagination: TablePaginationConfig = {
    pageSize: 10,
    current: filter.page as number,
    total: item,
    onChange: (page: number) => {
      setFilter({ ...filter, page });
    },
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  const handleSearchWithPublishTime = (date: any) => {
    if (date) {
      setFilter({
        ...filter,
        page: 1,
        startTime: date ? dayjs(date[0]).startOf('day').format('YYYY/MM/DD') : date,
        endTime: date ? dayjs(date[1]).endOf('day').format('YYYY/MM/DD') : date,
      });
    } else {
      setFilter({
        ...filter,
        limit: 10,
        page: 1,
        startTime: undefined,
        endTime: undefined,
      });
    }
  };

  const handleSearchByStatus = (status: any) => {
    setFilter({ ...filter, page: 1, status: status });
  };

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.value !== '') {
      setFilter({ ...filter, page: 1, keyword: event.target.value });
    } else {
      setFilter({ ...filter, page: 1, keyword: undefined });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Thời gian bắt đầu',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (time) => <>{dayjs(time).format('DD/MM/YYYY')}</>,
    },
    {
      title: 'UID player 1',
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'center',
    },
    {
      title: 'Username player 1',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
    },
    {
      title: 'Điểm player 1',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
    },
    {
      title: 'UID player 2',
      dataIndex: 'opponentRefId',
      key: 'opponentRefId',
      align: 'center',
    },
    {
      title: 'Username player 2',
      dataIndex: 'opponentUsername',
      key: 'opponentUsername',
      align: 'center',
    },
    {
      title: 'Điểm player 2',
      dataIndex: 'opponentScore',
      key: 'opponentScore',
      align: 'center',
    },
    {
      title: 'Kết quả',
      dataIndex: '',
      key: '',
      align: 'center',
      render: (_, record) => (
        <>
          {record?.score > record?.opponentScore ? 'Thắng' : record?.score === record?.opponentScore ? 'Hòa' : 'Thua'}
        </>
      ),
    },
    {
      title: 'Chuỗi thắng',
      dataIndex: 'repeatedWin',
      key: 'repeatedWin',
      align: 'center',
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: <p style={{ cursor: 'default' }}>Giải đấu online</p>,
          },
          {
            title: <p style={{ cursor: 'default' }}>PvP</p>,
          },
          { title: 'Danh sách' },
        ]}
      />
      <Card size="small">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Item required={false} label={<span>Tìm kiếm</span>} labelCol={{ span: 24 }} wrapperCol={{ span: 23 }}>
            <Input onChange={(e) => onChangeSearch(e)} placeholder="Tìm theo username, UID" />
          </Form.Item>

          <Form.Item
            required={false}
            label={<span>Tìm theo khoảng thời tìm kiếm</span>}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 23 }}
          >
            <DatePicker.RangePicker
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              onChange={(date) => handleSearchWithPublishTime(date)}
              inputReadOnly
            />
          </Form.Item>

          <Form.Item required={false} label={<span>Kết quả</span>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Select
              style={{ width: '200px' }}
              placeholder="Tất cả"
              allowClear
              onChange={(e) => handleSearchByStatus(e)}
            >
              {Display_Match_Status.map(({ value, label }) => (
                <Select.Option value={value}>{label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Card>
      <Container title="PvP">
        <Table
          columns={columns}
          dataSource={dataTable}
          rowKey="_id"
          pagination={dataTable?.length > 0 ? pagination : false}
        />
      </Container>
    </Space>
  );
};
