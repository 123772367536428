import { Navigate, createBrowserRouter } from 'react-router-dom';

import AchievementCreate from 'src/pages/achievement/AchievementCreate';
import AchievementEdit from 'src/pages/achievement/AchievementEdit';
import App from 'src/App';
import CommandSpecialCreate from 'src/pages/package/commandSpecial/CommandSpecialCreate';
import CommandSpecialEdit from 'src/pages/package/commandSpecial/CommandSpecialEdit';
import CommandSpecialList from 'src/pages/package/commandSpecial/CommandSpecialList';
import ConfigGiftEnergy from 'src/pages/config/ConfigGiftEnergy';
import DetailAchievement from 'src/pages/achievement/DetailAchievement';
import EffectivenessPromotionReport from 'src/pages/promotion/effectivenessPromotionReport/EffectivenessPromotionReport';
import Eligible from 'src/pages/promotion/eligible/Eligible';
import ErrorPage from 'src/components/ErrorPage';
import HistoryReceived from 'src/pages/achievement/historyReceivedAchievement/HistoryReceived';
import ItemDetail from 'src/pages/items/ItemDetail';
import ItemEdit from 'src/pages/items/ItemEdit';
import Login from 'src/pages/login';
import ParticipatingTeamEdit from 'src/pages/tournamentOffline/tournamentOfflineDetail/TeamInTour/TeamInTourEdit';
import PaymentHistoryDetail from 'src/pages/package/paymentHistory/PaymentHistoryDetail';
import PaymentHistoryList from 'src/pages/package/paymentHistory/PaymentHistoryList';
import Profile from 'src/pages/profile';
import PromotionDetail from 'src/pages/promotion/PromotionDetail';
import PromotionPlusBonus from 'src/pages/promotion/promotionPlusBonus/PromotionPlusBonus';
import RecordOfReconciliation from 'src/pages/report/RecordOfReconciliation';
import ResetPassword from 'src/pages/resetPassword';
import SendOTP from 'src/pages/2fa/SendOTP';
import { lazy } from 'react';
import PvP from 'src/pages/tournament/PvP/PvP';
import HistoryCDRMM from 'src/pages/package/historyCDRMM/HistoryCDRMM';

const GroupPermissionList = lazy(() => import('../pages/account/group/GroupPermissionList'));
const GroupPermissionCreate = lazy(() => import('../pages/account/group/GroupPermissionCreate'));
const GroupPermissionEdit = lazy(() => import('../pages/account/group/GroupPermissionEdit'));
const AdministratorList = lazy(() => import('../pages/account/administrator/AdministratorList'));
const AdministratorCreate = lazy(() => import('../pages/account/administrator/AdministratorCreate'));
const AdministratorEdit = lazy(() => import('../pages/account/administrator/AdministratorEdit'));
const ConfigLdapEdit = lazy(() => import('../pages/config/ConfigLdapEdit'));
const GameList = lazy(() => import('../pages/game/GameList'));
const GameCreate = lazy(() => import('../pages/game/GameCreate'));
const GameEdit = lazy(() => import('../pages/game/GameEdit'));
const GameG1List = lazy(() => import('../pages/game/g1/GameG1List'));
const GameG1Edit = lazy(() => import('../pages/game/g1/GameG1Edit'));
const GameG1Create = lazy(() => import('../pages/game/g1/GameG1Create'));
const NewsList = lazy(() => import('../pages/news/NewsList'));
const NewsCreate = lazy(() => import('../pages/news/NewsCreate'));
const NewsEdit = lazy(() => import('../pages/news/NewsEdit'));
const NewsDetail = lazy(() => import('../pages/news/NewsDetail'));
const PackageManagementList = lazy(() => import('../pages/package/package/PackageList'));
const PackageManagementCreate = lazy(() => import('../pages/package/package/PackageCreate'));
const PackageManagementEdit = lazy(() => import('../pages/package/package/PackageEdit'));
const PackageManagementDetail = lazy(() => import('../pages/package/package/PackageDetail'));

// event
const EventList = lazy(() => import('../pages/tournament/event/EventList'));
const EventCreate = lazy(() => import('../pages/tournament/event/EventCreate'));
const EventEdit = lazy(() => import('../pages/tournament/event/EventEdit'));
const EventDetail = lazy(() => import('../pages/tournament/event/EventDetail'));

// room
const RoomList = lazy(() => import('src/pages/tournament/room/RoomList'));
const RoomDetail = lazy(() => import('src/pages/tournament/room/RoomDetail'));

const MissionV2List = lazy(() => import('../pages/missionv2/MissionList'));
const MissionV2Create = lazy(() => import('../pages/missionv2/MissionCreate'));
const MissionV2Edit = lazy(() => import('../pages/missionv2/MissionEdit'));
const MissionV2Detail = lazy(() => import('../pages/missionv2/MissionDetail'));

const LoyaltyList = lazy(() => import('../pages/loyalty/LoyaltyList'));
const LoyaltyEdit = lazy(() => import('../pages/loyalty/LoyaltyEdit'));
const AccountList = lazy(() => import('../pages/account/customer/AccountList'));
const AccountCreate = lazy(() => import('../pages/account/customer/AccountCreate'));
const AccountEdit = lazy(() => import('../pages/account/customer/AccountEdit'));
const TagList = lazy(() => import('../pages/tag/TagList'));
const TagCreate = lazy(() => import('../pages/tag/TagCreate'));
const TagEdit = lazy(() => import('../pages/tag/TagEdit'));
const CategoryList = lazy(() => import('../pages/category/CategoryList'));
const CategoryCreate = lazy(() => import('../pages/category/CategoryCreate'));
const CategoryEdit = lazy(() => import('../pages/category/CategoryEdit'));
const CategoryDetail = lazy(() => import('../pages/category/CategoryDetail'));
const SliderList = lazy(() => import('../pages/slider/SliderList'));
const SliderCreate = lazy(() => import('../pages/slider/SliderCreate'));
const SliderEdit = lazy(() => import('../pages/slider/SliderEdit'));
const SliderDetail = lazy(() => import('../pages/slider/SliderDetail'));
const VideoList = lazy(() => import('../pages/video/VideoList'));
const VideoCreate = lazy(() => import('../pages/video/VideoCreate'));
const VideoEdit = lazy(() => import('../pages/video/VideoEdit'));
const VideoDetail = lazy(() => import('../pages/video/VideoDetail'));
const PromotionList = lazy(() => import('../pages/promotion/PromotionList'));
const PromotionCreate = lazy(() => import('../pages/promotion/PromotionCreate'));
const PromotionEdit = lazy(() => import('../pages/promotion/PromotionEdit'));
const PayRewards = lazy(() => import('../pages/payRewards/PayRewards'));
const HistoryPayRewards = lazy(() => import('../pages/historyPayRewards/historyPayRewards'));
const CommentList = lazy(() => import('../pages/comment/CommentList'));
const LogAccountList = lazy(() => import('../pages/log/account/LogAccountList'));
const LogAccountDetail = lazy(() => import('../pages/log/account/LogAccountDetail'));
const LogContentList = lazy(() => import('../pages/log/content/LogContentList'));
const LogContentDetail = lazy(() => import('../pages/log/content/LogContentDetail'));
const RegisterDetail = lazy(() => import('../pages/package/register/RegisterDetail'));
const RegisterList = lazy(() => import('../pages/package/register/RegisterList'));
const ReportPackageRevenue = lazy(() => import('../pages/report/Revenue'));
const ReportSubscriptionCancellation = lazy(() => import('../pages/report/SubscriptionCancellation'));
const RevenueDataIP = lazy(() => import('../pages/report/RevenueDataIP'));
const LogPackageList = lazy(() => import('../pages/log/package/LogPackageList'));
const ConfigTokenToEnergy = lazy(() => import('../pages/config/ConfigTokenToEnergy'));
const FeedbacksGame = lazy(() => import('../pages/favoriteGame/FeedbacksGame'));
const FeedbackGameDetail = lazy(() => import('../pages/favoriteGame/FeedbackGameDetail'));
const TableRankingList = lazy(() => import('../pages/tableRankings/TableRankingList'));
const TournamentOffline = lazy(() => import('../pages/tournamentOffline/TournamentOffline'));
const TournamentOfflineDetail = lazy(
  () => import('../pages/tournamentOffline/tournamentOfflineDetail/TournamentOfflineDetail'),
);
const Footer = lazy(() => import('../pages/footer/Footer'));
const FooterCreate = lazy(() => import('../pages/footer/FooterCreate'));
const FooterEdit = lazy(() => import('../pages/footer/FooterEdit'));
const TournamentOfflineEdit = lazy(() => import('../pages/tournamentOffline/TournamentOfflineEdit'));
const Contacts = lazy(() => import('../pages/contact/Contacts'));
const FooterDetail = lazy(() => import('../pages/footer/FooterDetail'));
const AdsList = lazy(() => import('../pages/ads/AdsList'));
const AdsEdit = lazy(() => import('../pages/ads/AdsEdit'));
const AdsDetail = lazy(() => import('../pages/ads/AdsDetail'));
const AdsCreate = lazy(() => import('../pages/ads/AdsCreate'));
const TourOfflineVideo = lazy(
  () => import('../pages/tournamentOffline/tournamentOfflineDetail/videos/TourOfflineVideo'),
);
const ConfigPackage = lazy(() => import('../pages/config/ConfigPackage'));
const AccountDetail = lazy(() => import('../pages/account/customer/AccountDetail'));
const TournamentOfflineNews = lazy(
  () => import('../pages/tournamentOffline/tournamentOfflineNews/TournamentOfflineNews'),
);
const RankingTournamentOnlineEdit = lazy(
  () => import('../pages/tournament/event/rankingTourOnl/RankingTournamentOnlineEdit'),
);
const TournamentOfflineNewsDetail = lazy(
  () => import('../pages/tournamentOffline/tournamentOfflineNews/TournamentOfflineNewsDetail'),
);
const CPList = lazy(() => import('../pages/cp/CPList'));
const CPCreate = lazy(() => import('../pages/cp/CPCreate'));
const CPEdit = lazy(() => import('../pages/cp/CPEdit'));
const CPDetail = lazy(() => import('../pages/cp/CPDetail'));
const FirstPurchaseContinuous = lazy(() => import('../pages/report/FirstPurchaseContinuous'));
const ConfigMobileIntro = lazy(() => import('../pages/config/MobileIntro/ConfigMobileIntro'));
const PromotionIntergration = lazy(() => import('../pages/promotion/promotionIntergration/PromotionIntergration'));
const PromotionConfig = lazy(() => import('../pages/promotion/promotionConfig/PromotionConfig'));
const PromotionGift = lazy(() => import('../pages/promotion/promotionGift/PromotionGift'));
const PromotionGiving = lazy(() => import('../pages/promotion/promotionGiving/PromotionGiving'));
const PromotionIntergrationCreate = lazy(
  () => import('../pages/promotion/promotionIntergration/PromotionIntergrationCreate'),
);
const PromotionIntergrationEdit = lazy(
  () => import('../pages/promotion/promotionIntergration/PromotionIntergrationEdit'),
);
const PromotionConfigCreate = lazy(() => import('../pages/promotion/promotionConfig/PromotionConfigCreate'));
const PromotionConfigEdit = lazy(() => import('../pages/promotion/promotionConfig/PromotionConfigEdit'));
const PromotionGiftCreate = lazy(() => import('../pages/promotion/promotionGift/PromotionGiftCreate'));
const PromotionGiftEdit = lazy(() => import('../pages/promotion/promotionGift/PromotionGiftEdit'));
const RegionalCompanies = lazy(() => import('../pages/account/regionalCompany/RegionalCompanies'));
const RegionalCompaniesCreate = lazy(() => import('../pages/account/regionalCompany/RegionalCompanyCreate'));
const RegionalCompaniesEdit = lazy(() => import('../pages/account/regionalCompany/RegionalCompaniesEdit'));
const ConfigMobileIntroCreate = lazy(() => import('../pages/config/MobileIntro/ConfigMobileIntroCreate'));
const ConfigMobileIntroEdit = lazy(() => import('../pages/config/MobileIntro/ConfigMobileIntroEdit'));
const RevenueSubCommand = lazy(() => import('../pages/report/RevenueSubCommand'));
const ConfigModule = lazy(() => import('../pages/config/ConfigModule/ConfigModule'));
const ConfigModuleDetail = lazy(() => import('../pages/config/ConfigModule/ConfigModuleDetail'));
const ConfigModuleEdit = lazy(() => import('../pages/config/ConfigModule/ConfigModuleEdit'));
const ConfigModuleCreate = lazy(() => import('../pages/config/ConfigModule/ConfigModuleCreate'));
const ConfigSubModuleCreate = lazy(() => import('../pages/config/ConfigModule/ConfigSubModule/ConfigSubModuleCreate'));
const ConfigSubModuleEdit = lazy(() => import('../pages/config/ConfigModule/ConfigSubModule/ConfigSubModuleEdit'));
const ConfigSubModuleDetail = lazy(() => import('../pages/config/ConfigModule/ConfigSubModule/ConfigSubModuleDetail'));
const PromotionArtifacts = lazy(() => import('../pages/promotionArtifact/PromotionArtifacts'));
const PromotionArtifactCreate = lazy(() => import('../pages/promotionArtifact/PromotionArtifactCreate'));
const PromotionArtifactEdit = lazy(() => import('../pages/promotionArtifact/PromotionArtifactEdit'));
const PromotionArtifactDetail = lazy(() => import('../pages/promotionArtifact/PromotionArtifactDetail'));
const PromotionArtifactCode = lazy(() => import('../pages/promotionArtifact/Code/PromotionArtifactCode'));
const PromotionArtifactReward = lazy(() => import('../pages/promotionArtifact/Reward/PromotionArtifactReward'));
const PromotionArtifactCodeEdit = lazy(() => import('../pages/promotionArtifact/Code/PromotionArtifactCodeEdit'));
const PromotionArtifactRewardCreate = lazy(
  () => import('../pages/promotionArtifact/Reward/PromotionArtifactRewardCreate'),
);
const PromotionArtifactRewardEdit = lazy(() => import('../pages/promotionArtifact/Reward/PromotionArtifactRewardEdit'));
const PortalIntro = lazy(() => import('../pages/config/PortalIntro/PortalIntro'));
const PortalIntroCreate = lazy(() => import('../pages/config/PortalIntro/PortalIntroCreate'));
const PortalIntroEdit = lazy(() => import('../pages/config/PortalIntro/PortalIntroEdit'));
const ReportStatistic = lazy(() => import('src/pages/report/ReportStatistic'));
const PackageHistory = lazy(() => import('src/pages/package/packageHistory/PackageHistory'));
const PromotionArtifactRewardDetail = lazy(
  () => import('src/pages/promotionArtifact/Reward/PromotionArtifactRewardDetail'),
);
const PackageHistoryDetail = lazy(() => import('src/pages/package/packageHistory/PackageHistoryDetail'));
const TransactionHistory = lazy(() => import('src/pages/package/TransactionHistory/TransactionHistory'));
const ReportCPstatistic = lazy(() => import('src/pages/contentProviderReport/reportCPstatistic'));
const CPRevenueReport = lazy(() => import('src/pages/contentProviderReport/revenueReportCP/index'));
const ReportSubcription = lazy(() => import('src/pages/contentProviderReport/ReportSubcription'));
const ReportByCMDCode = lazy(() => import('src/pages/contentProviderReport/ReportByCMDCode'));
const SubcriptionHistory = lazy(() => import('src/pages/contentProviderReport/historySubcription/HistorySub'));
const Items = lazy(() => import('src/pages/items/Items'));
const ItemCreate = lazy(() => import('src/pages/items/ItemCreate'));
const ItemHistory = lazy(() => import('src/pages/items/itemHistory/ItemHistory'));
const Milestone = lazy(() => import('src/pages/milestone/Milestone'));
const MilestoneCreate = lazy(() => import('src/pages/milestone/MilestoneCreate'));
const MilestoneDetail = lazy(() => import('src/pages/milestone/MilestoneDetail'));
const Achievements = lazy(() => import('src/pages/achievement/Achievements'));

const routes = [
  {
    path: '/',
    element: <App />,
    loader: () => ({ isAdmin: true }),
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            path: '/:slug/:slug-group',
            children: [
              { element: <Navigate to="/:slug-group/list" replace />, index: true },
              { path: 'list', element: <GroupPermissionList /> },
              { path: 'create', element: <GroupPermissionCreate /> },
              { path: 'edit/:_id', element: <GroupPermissionEdit /> },
            ],
          },
          {
            path: '/:slug/:slug-administrator',
            children: [
              { element: <Navigate to="/:slug-administrator/list" replace />, index: true },
              { path: 'list', element: <AdministratorList /> },
              { path: 'create', element: <AdministratorCreate /> },
              { path: 'edit/:_id', element: <AdministratorEdit /> },
            ],
          },
          {
            path: 'game/g234',
            children: [
              { element: <Navigate to="/game/g234/list" replace />, index: true },
              { path: 'list', element: <GameList /> },
              { path: 'create', element: <GameCreate /> },
              { path: 'edit/:_id', element: <GameEdit /> },
            ],
          },

          {
            path: 'game/g1',
            children: [
              { element: <Navigate to="/game/g1/list" replace />, index: true },
              { path: 'list', element: <GameG1List /> },
              { path: 'create', element: <GameG1Create /> },
              { path: 'edit/:_id', element: <GameG1Edit /> },
            ],
          },
          {
            path: 'game/feedback',
            children: [
              { element: <Navigate to="/game/feedback/list" replace />, index: true },
              { path: 'list', element: <FeedbacksGame /> },
              { path: 'detail/:_id', element: <FeedbackGameDetail /> },
            ],
          },
          {
            path: 'game/score-statistics',
            children: [
              { element: <Navigate to="/game/score-statistics/list" replace />, index: true },
              { path: 'list', element: <TableRankingList /> },
            ],
          },
          {
            path: 'news/all-news',
            children: [
              { element: <Navigate to="/news/all-news/list" replace />, index: true },
              { path: 'list', element: <NewsList /> },
              { path: 'create', element: <NewsCreate /> },
              { path: 'edit/:_id', element: <NewsEdit /> },
              { path: 'detail/:_id', element: <NewsDetail /> },
            ],
          },
          {
            path: 'tournament-online/event',
            children: [
              { element: <Navigate to="tournament-online/event/list" replace />, index: true },
              { path: 'list', element: <EventList /> },
              { path: 'create', element: <EventCreate /> },
              { path: 'edit/:_id', element: <EventEdit /> },
              { path: 'detail/:_id', element: <EventDetail /> },
              { path: 'ranking-tournament-online/edit/:_id', element: <RankingTournamentOnlineEdit /> },
            ],
          },
          {
            path: 'tournament-online/room',
            children: [
              { element: <Navigate to="tournament-online/room/list" replace />, index: true },
              { path: 'list', element: <RoomList /> },
              { path: 'detail/:_id', element: <RoomDetail /> },
            ],
          },
          {
            path: 'tournament-online/PvP',
            children: [
              { element: <Navigate to="tournament-online/PvP/list" replace />, index: true },
              { path: 'list', element: <PvP /> },
            ],
          },
          {
            path: 'tournament-offline/all-tour-offline',
            children: [
              { element: <Navigate to="tournament-offline/all-tour-offline/list" replace />, index: true },
              { path: 'list', element: <TournamentOffline /> },
              { path: 'video/list', element: <TourOfflineVideo /> },
              { path: 'news/list', element: <TournamentOfflineNews /> },
              { path: 'news/detail/:_id', element: <TournamentOfflineNewsDetail /> },
              { path: 'edit/:_id', element: <TournamentOfflineEdit /> },
              { path: 'detail/:_id', element: <TournamentOfflineDetail /> },
              { path: 'team-in-tournament/edit/:_id', element: <ParticipatingTeamEdit /> },
            ],
          },
          {
            path: 'mission/all-mission',
            children: [
              { element: <Navigate to="/mission/all-mission/list" replace />, index: true },
              { path: 'list', element: <MissionV2List /> },
              { path: 'create', element: <MissionV2Create /> },
              { path: 'edit/:_id', element: <MissionV2Edit /> },
              { path: 'detail/:_id', element: <MissionV2Detail /> },
            ],
          },
          // {
          //   path: 'mission-v2',
          //   children: [
          //     { element: <Navigate to="/mission-v2/list" replace />, index: true },
          //     { path: 'list', element: <MissionV2List /> },
          //     { path: 'create', element: <MissionV2Create /> },
          //     { path: 'edit/:_id', element: <MissionV2Edit /> },
          //     { path: 'detail/:_id', element: <MissionV2Detail /> },
          //   ],
          // },
          {
            path: 'ads/all-ads',
            children: [
              { element: <Navigate to="/ads/all-ads/list" replace />, index: true },
              { path: 'list', element: <AdsList /> },
              { path: 'create', element: <AdsCreate /> },
              { path: 'edit/:_id', element: <AdsEdit /> },
              { path: 'detail/:_id', element: <AdsDetail /> },
            ],
          },
          {
            path: '/:slug/:slug-customer',
            children: [
              { element: <Navigate to="/:slug-customer/list" replace />, index: true },
              { path: 'list', element: <AccountList /> },
              { path: 'create', element: <AccountCreate /> },
              { path: 'edit/:_id', element: <AccountEdit /> },
              { path: 'detail/:_id', element: <AccountDetail /> },
            ],
          },
          {
            path: 'loyalty',
            children: [
              { element: <Navigate to="/loyalty/list" replace />, index: true },
              { path: 'list', element: <LoyaltyList /> },
              { path: 'edit/:_id', element: <LoyaltyEdit /> },
            ],
          },
          {
            path: '/:slug/:slug-tag',
            children: [
              { element: <Navigate to="/:slug-tag/list" replace />, index: true },
              { path: 'list/:type', element: <TagList /> },
              { path: 'create/:type', element: <TagCreate /> },
              { path: 'edit/:_id', element: <TagEdit /> },
            ],
          },
          {
            path: '/:slug/:slug-comment',
            children: [
              { element: <Navigate to="/:slug-comment/list" replace />, index: true },
              { path: 'list', element: <CommentList /> },
            ],
          },
          {
            path: '/:slug/:slug-category',
            children: [
              { element: <Navigate to="/:slug-category/list" replace />, index: true },
              { path: 'list/:type', element: <CategoryList /> },
              { path: 'create/:type', element: <CategoryCreate /> },
              { path: 'edit/:_id', element: <CategoryEdit /> },
              { path: 'detail/:_id', element: <CategoryDetail /> },
            ],
          },
          {
            path: '/:slug/:slug-slider',
            children: [
              { element: <Navigate to="/:slug-slider/list" replace />, index: true },
              { path: 'list/:type', element: <SliderList /> },
              { path: 'create/:type', element: <SliderCreate /> },
              { path: 'edit/:_id', element: <SliderEdit /> },
              { path: 'detail/:_id', element: <SliderDetail /> },
            ],
          },
          {
            path: 'video/all-video',
            children: [
              { element: <Navigate to="/video/all-video/list" replace />, index: true },
              { path: 'list', element: <VideoList /> },
              { path: 'create', element: <VideoCreate /> },
              { path: 'edit/:_id', element: <VideoEdit /> },
              { path: 'detail/:_id', element: <VideoDetail /> },
            ],
          },
          {
            path: 'promotion',
            children: [
              { element: <Navigate to="/promotion/list" replace />, index: true },
              { path: 'promotion-campain/list', element: <PromotionList /> },
              { path: 'promotion-campain/create', element: <PromotionCreate /> },
              { path: 'promotion-campain/edit/:_id', element: <PromotionEdit /> },
              { path: 'promotion-campain/detail/:_id', element: <PromotionDetail /> },
              { path: 'pay-rewards', element: <PayRewards /> },
              { path: 'history-pay-rewards', element: <HistoryPayRewards /> },
              { path: 'promotion-intergration', element: <PromotionIntergration /> },
              { path: 'promotion-intergration/create', element: <PromotionIntergrationCreate /> },
              { path: 'promotion-intergration/edit/:_id', element: <PromotionIntergrationEdit /> },
              { path: 'promotion-config', element: <PromotionConfig /> },
              { path: 'promotion-config/create', element: <PromotionConfigCreate /> },
              { path: 'promotion-config/edit/:_id', element: <PromotionConfigEdit /> },
              { path: 'promotion-gift', element: <PromotionGift /> },
              { path: 'promotion-gift/create', element: <PromotionGiftCreate /> },
              { path: 'promotion-gift/edit/:_id', element: <PromotionGiftEdit /> },
              { path: 'gifts-giving', element: <PromotionGiving /> },
              { path: 'plus-bonus', element: <PromotionPlusBonus /> },
              { path: 'eligible', element: <Eligible /> },
              { path: 'effectiveness-promotion-report', element: <EffectivenessPromotionReport /> },
            ],
          },
          {
            path: 'promotion-intergration',
            children: [
              { element: <Navigate to="/promotion-intergration/list" replace />, index: true },
              { path: 'promotion-intergration-programs/list', element: <PromotionIntergration /> },
              { path: 'promotion-intergration-programs/create', element: <PromotionIntergrationCreate /> },
              { path: 'promotion-intergration-programs/edit/:_id', element: <PromotionIntergrationEdit /> },
              { path: 'pay-rewards', element: <PayRewards /> },
              { path: 'promotion-config', element: <PromotionConfig /> },
              { path: 'promotion-config/create', element: <PromotionConfigCreate /> },
              { path: 'promotion-config/edit/:_id', element: <PromotionConfigEdit /> },
              { path: 'promotion-gift', element: <PromotionGift /> },
              { path: 'promotion-gift/create', element: <PromotionGiftCreate /> },
              { path: 'promotion-gift/edit/:_id', element: <PromotionGiftEdit /> },
              { path: 'gifts-giving', element: <PromotionGiving /> },
            ],
          },
          {
            path: 'promotion-artifacts/all-promotion-artifacts',
            children: [
              { element: <Navigate to="/promotion-artifacts/all-promotion-artifacts/list" replace />, index: true },
              { path: 'list', element: <PromotionArtifacts /> },
              { path: 'create', element: <PromotionArtifactCreate /> },
              { path: 'edit/:_id', element: <PromotionArtifactEdit /> },
              { path: 'detail/:_id', element: <PromotionArtifactDetail /> },
              { path: 'codes/list', element: <PromotionArtifactCode /> },
              { path: 'codes/edit/:_id', element: <PromotionArtifactCodeEdit /> },
              { path: 'rewards/list', element: <PromotionArtifactReward /> },
              { path: 'rewards/create', element: <PromotionArtifactRewardCreate /> },
              { path: 'rewards/edit/:_id', element: <PromotionArtifactRewardEdit /> },
              { path: 'rewards/detail/:_id', element: <PromotionArtifactRewardDetail /> },
            ],
          },
          {
            path: 'config/ldap-info',
            children: [
              { element: <Navigate to="/config/ldap-info/list" replace />, index: true },
              { path: 'edit', element: <ConfigLdapEdit /> },
            ],
          },
          {
            path: 'log/account',
            children: [
              { element: <Navigate to="/log/account/list" replace />, index: true },
              { path: 'list', element: <LogAccountList /> },
              { path: 'detail/:_id', element: <LogAccountDetail /> },
            ],
          },
          {
            path: 'account/regional-company',
            children: [
              { element: <Navigate to="/account/regional-company/list" replace />, index: true },
              { path: 'list', element: <RegionalCompanies /> },
              { path: 'create', element: <RegionalCompaniesCreate /> },
              { path: 'edit/:_id', element: <RegionalCompaniesEdit /> },
            ],
          },
          {
            path: 'log/content',
            children: [
              { element: <Navigate to="/log/content/list" replace />, index: true },
              { path: 'list', element: <LogContentList /> },
              { path: 'detail/:_id', element: <LogContentDetail /> },
            ],
          },
          {
            path: 'log/package',
            children: [
              { element: <Navigate to="/log/package/list" replace />, index: true },
              { path: 'list', element: <LogPackageList /> },
            ],
          },
          {
            path: 'config/tokenToEnergy',
            children: [
              { element: <Navigate to="/config/tokenToEnergy/edit" replace />, index: true },
              { path: 'edit', element: <ConfigTokenToEnergy /> },
            ],
          },
          {
            path: 'config/give-energy',
            children: [
              { element: <Navigate to="/config/give-energy/edit" replace />, index: true },
              { path: 'edit', element: <ConfigGiftEnergy /> },
            ],
          },
          {
            path: 'config/footer',
            children: [
              { element: <Navigate to="/config/footer/list" replace />, index: true },
              { path: 'list', element: <Footer /> },
              { path: 'create', element: <FooterCreate /> },
              { path: 'edit/:_id', element: <FooterEdit /> },
              { path: 'detail/:_id', element: <FooterDetail /> },
            ],
          },
          {
            path: 'config/package',
            children: [
              { element: <Navigate to="/config/package/edit" replace />, index: true },
              { path: 'edit', element: <ConfigPackage /> },
            ],
          },
          {
            path: 'config/mobileIntro',
            children: [
              { element: <Navigate to="/config/mobileIntro/edit" replace />, index: true },
              { path: 'list', element: <ConfigMobileIntro /> },
              { path: 'create', element: <ConfigMobileIntroCreate /> },
              { path: 'edit/:_id', element: <ConfigMobileIntroEdit /> },
            ],
          },
          {
            path: 'config/portal-intro',
            children: [
              { element: <Navigate to="/config/portal-intro/list" replace />, index: true },
              { path: 'list', element: <PortalIntro /> },
              { path: 'create', element: <PortalIntroCreate /> },
              { path: 'edit/:_id', element: <PortalIntroEdit /> },
            ],
          },
          {
            path: 'config/module',
            children: [
              { element: <Navigate to="/config/module/list" replace />, index: true },
              { path: 'list', element: <ConfigModule /> },
              { path: 'create', element: <ConfigModuleCreate /> },
              { path: 'create/:_id', element: <ConfigSubModuleCreate /> },
              { path: 'edit/:_id', element: <ConfigModuleEdit /> },
              { path: 'detail/:_id', element: <ConfigModuleDetail /> },
            ],
          },
          {
            path: 'config/sub-module',
            children: [
              { element: <Navigate to="/config/sub-module/list" replace />, index: true },
              { path: 'create/:_id', element: <ConfigSubModuleCreate /> },
              { path: 'edit/:_id', element: <ConfigSubModuleEdit /> },
              { path: 'detail/:_id', element: <ConfigSubModuleDetail /> },
            ],
          },
          {
            path: 'package/all-package',
            children: [
              { element: <Navigate to="/package/all-package/list" replace />, index: true },
              { path: 'list', element: <PackageManagementList /> },
              { path: 'create', element: <PackageManagementCreate /> },
              { path: 'edit/:_id', element: <PackageManagementEdit /> },
              { path: 'detail/:_id', element: <PackageManagementDetail /> },
            ],
          },
          {
            path: 'package/register',
            children: [
              { element: <Navigate to="/package/register/list" replace />, index: true },
              { path: 'list', element: <RegisterList /> },
              { path: 'detail/:_id', element: <RegisterDetail /> },
            ],
          },
          {
            path: 'package/package-history',
            children: [
              { element: <Navigate to="/package/package-history/list" replace />, index: true },
              { path: 'list', element: <PackageHistory /> },
              { path: 'detail/:_id', element: <PackageHistoryDetail /> },
            ],
          },
          {
            path: 'package/transaction-history',
            children: [
              { element: <Navigate to="/package/transaction-history/list" replace />, index: true },
              { path: 'list', element: <TransactionHistory /> },
            ],
          },
          {
            path: 'package/command-special',
            children: [
              { element: <Navigate to="/package/command-special/list" replace />, index: true },
              { path: 'list', element: <CommandSpecialList /> },
              { path: 'create', element: <CommandSpecialCreate /> },
              { path: 'edit/:_id', element: <CommandSpecialEdit /> },
            ],
          },
          {
            path: 'package/payment-history',
            children: [
              { element: <Navigate to="/package/payment-history" replace />, index: true },
              { path: 'list', element: <PaymentHistoryList /> },
              { path: 'detail/:transactionId', element: <PaymentHistoryDetail /> },
            ],
          },
          {
            path: 'package/history-cdr-mm',
            children: [
              { element: <Navigate to="/package/history-cdr-mm" replace />, index: true },
              { path: 'list', element: <HistoryCDRMM /> },
            ],
          },
          {
            path: 'account/contact',
            children: [
              { element: <Navigate to="/account/contact/list" replace />, index: true },
              { path: 'list', element: <Contacts /> },
            ],
          },
          {
            path: '/profile',
            element: <Profile />,
          },
          {
            path: 'report',
            children: [
              { element: <Navigate to="/report/revenue" replace />, index: true },
              { path: 'revenue', element: <ReportPackageRevenue /> },
              { path: 'subscription-cancellation', element: <ReportSubscriptionCancellation /> },
              { path: 'data-ip', element: <RevenueDataIP /> },
              { path: 'first-purchase-continuous', element: <FirstPurchaseContinuous /> },
              { path: 'revenue-sub-command', element: <RevenueSubCommand /> },
              { path: 'revenue-statistic', element: <ReportStatistic /> },
              { path: 'record-of-reconciliation', element: <RecordOfReconciliation /> },
            ],
          },
          {
            path: 'content-provider/all-CP',
            children: [
              { element: <Navigate to="/content-provider/all-CP/list" replace />, index: true },
              { path: 'list', element: <CPList /> },
              { path: 'create', element: <CPCreate /> },
              { path: 'edit/:type/:_id', element: <CPEdit /> },
              { path: 'detail/:type/:_id', element: <CPDetail /> },
            ],
          },
          {
            path: 'content-provider-report',
            children: [
              { element: <Navigate to="/content-provider-report/report-cp-statistic" replace />, index: true },
              { path: 'report-cp-statistic', element: <ReportCPstatistic /> },
              { path: 'content-provider-report-revenue', element: <CPRevenueReport /> },
              { path: 'report-subcription', element: <ReportSubcription /> },
              { path: 'report-by-CMD', element: <ReportByCMDCode /> },
              { path: 'look-up-subcription-history', element: <SubcriptionHistory /> },
            ],
          },
          {
            path: 'achievements-milestone',
            children: [
              { element: <Navigate to="achievements-milestone/milestone-list" replace />, index: true },
              { path: 'milestone-list', element: <Milestone /> },
              { path: 'milestone/create', element: <MilestoneCreate /> },
              { path: 'milestone/detail/:_id', element: <MilestoneDetail /> },
              { path: 'achievement-list', element: <Achievements /> },
              { path: 'achievement/create', element: <AchievementCreate /> },
              { path: 'achievement/edit/:_id', element: <AchievementEdit /> },
              { path: 'achievement/detail/:_id', element: <DetailAchievement /> },
              { path: 'history-received-achievement', element: <HistoryReceived /> },
            ],
          },
          {
            path: 'items',
            children: [
              { element: <Navigate to="items/item-list" replace />, index: true },
              { path: 'item-list', element: <Items /> },
              { path: 'item-list/create', element: <ItemCreate /> },
              { path: 'item-list/edit/:_id', element: <ItemEdit /> },
              { path: 'items-history', element: <ItemHistory /> },
              { path: 'item-list/detail/:_id', element: <ItemDetail /> },
            ],
          },
          {
            path: '*',
            element: <Navigate to="/" replace={true} />,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/login-by-otp',
    element: <SendOTP />,
  },
  {
    path: '/auth/reset-password',
    element: <ResetPassword />,
  },
];

export default createBrowserRouter(routes);
